// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --blue: #007BFF;
  --darkBlue: #003FFF;
  --intense-blue: #0000FF;
  --green: #08BD08;
  --darkGreen: #008000;
  --grey: #606060;

  --grey-shadow: #E0E0E0;

  --body-background: #ffffff;
}

body {
  margin: 0;
  padding: 0;
  font: 0.9rem "Century Gothic", Futura, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--body-background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,oBAAoB;EACpB,eAAe;;EAEf,sBAAsB;;EAEtB,0BAA0B;AAC5B;;AAEA;EACE,SAAS;EACT,UAAU;EACV,4DAA4D;EAC5D,mCAAmC;EACnC,kCAAkC;EAClC,wCAAwC;AAC1C;;AAEA;EACE;aACW;AACb","sourcesContent":[":root {\n  --blue: #007BFF;\n  --darkBlue: #003FFF;\n  --intense-blue: #0000FF;\n  --green: #08BD08;\n  --darkGreen: #008000;\n  --grey: #606060;\n\n  --grey-shadow: #E0E0E0;\n\n  --body-background: #ffffff;\n}\n\nbody {\n  margin: 0;\n  padding: 0;\n  font: 0.9rem \"Century Gothic\", Futura, sans-serif !important;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: var(--body-background);\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
